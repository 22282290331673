<template>
  <div class="address-input">
    <div>
      <small class="form-control-feedback text-danger float-right" v-if="errMsg.geoErrMsg"><i class="fa fa-exclamation-triangle" />  {{ errMsg.geoErrMsg }}</small>
      <Input
        v-model="address.geo"
        :label="$t('address.geo')"
        :error="errMsg.geoErrMsg"
        @input="handleGeoChange"
        @blur="handleGeoChange"
      />
    </div>
    <div class="autocomplete-wrapper">
      <Input
        v-model="address.address"
        :label="$t('address.address')"
        @input="handleAddressInput"
        @blur="emitAddress"
        @change="emitAddress"
      />
      <div class="autocomplete-results" v-if="showAddressSuggestions">
        <ul>
          <li v-for="item in addressSuggestions" :key="item.id" @click="selectAddressSuggestion(item)">
            <span>{{ item.name }}</span>,  ({{ item.locationDetails }})
          </li>
        </ul>
      </div>
    </div>

    <Input
      ref="autoCompleteInput"
      v-model="address.district"
      :label="$t('address.districtId')"
      @blur="emitAddress"
      @change="emitAddress"
    />

    <Input
      v-model="address.region"
      :label="$t('address.regionId')"
      @blur="emitAddress"
      @change="emitAddress"
    />
    <Input
      v-model="address.country"
      :label="$t('address.countryId')"
      @blur="emitAddress"
      @change="emitAddress"
    />
    <MapComponent
      v-if="isValidGeo(address.geo)"
      :geo="address.geo"
      @errMsg="geoErrMsg"
    />
  </div>
</template>

<script>
import appConfig from '@/config'
import Input from '@/components/form/inputs/Input'
import MapComponent from '@/components/address/MapComponent'

export default {
  name: 'AddressInput',
  components: {
    Input,
    MapComponent
  },
  data () {
    return {
      API_KEY: appConfig.mapyCZ.xApiKey(),
      API_URL: appConfig.mapyCZ.apiUrl(),
      address: {
        geo: '',
        address: '',
        district: '',
        region: '',
        country: '',
        population: ''
      },
      errMsg: {
        geoErrMsg: null
      },
      addressSuggestions: [],
      countrySuggestions: [],
      showAddressSuggestions: false,
      showCountrySuggestions: false,
      showSuggestions: false
    }
  },
  props: {
    tag: {
      type: Object,
      default: () => ({ additionalInfo: {} })
    },
    tagType: {
      type: String,
      default: ''
    }
  },
  computed: {
    tagTypeInfo () {
      return this.tag.additionalInfo[this.tagType] || {}
    }
  },
  methods: {
    isValidGeo (geo) {
      const geoPattern = /^-?\d+(\.\d+)?,-?\d+(\.\d+)?$/
      return geoPattern.test(geo)
    },
    async searchAddress () {
      if (this.address.address.length < 3) {
        this.addressSuggestions = []
        this.showAddressSuggestions = false
        return
      }
      try {
        const response = await fetch(`${this.API_URL}/suggest?lang=cs&limit=5&type=regional.address&apikey=${this.API_KEY}&query=${this.address.address}`)
        const data = await response.json()
        this.addressSuggestions = data.items.map(item => {
          const regionalCountry = item.regionalStructure.find(i => i.type === 'regional.country') ?? {}
          const regionalRegions = item.regionalStructure.filter(i => i.type === 'regional.region') ?? []
          let location = {}
          let region = {}

          if (regionalRegions.length > 0) {
            location = regionalRegions.find(r => r.name.toLowerCase().includes('okres'))
            region = regionalRegions.find(r => r.name.toLowerCase().includes('kraj'))
          }

          return {
            name: item.name,
            locationDetails: item.location,
            country: regionalCountry?.name,
            location: location?.name,
            region: region?.name,
            data: item
          }
        })
        this.showAddressSuggestions = true
      } catch (error) {
        console.error('Error fetching suggestions:', error)
        this.addressSuggestions = []
        this.showAddressSuggestions = false
      }
    },
    handleAddressInput () {
      if (!this.address.address || this.address.address.length < 3) {
        this.address.geo = ''
        this.showAddressSuggestions = false
      } else {
        this.searchAddress()
      }
    },
    updateAddress (item, type) {
      this.address.geo = `${item.data.position.lat},${item.data.position.lon}`
      this.address.country = type === 'address' ? item.country : item.value
      this.address.address = type === 'address' ? `${item.name},${item.locationDetails}` : ''
      this.address.district = type === 'address' ? item.location : ''
      this.address.region = type === 'address' ? item.region : ''
      this.showAddressSuggestions = false
      this.showCountrySuggestions = false
      this.$emit('newAddress', {
        type: this.tagType,
        address: this.address
      })
    },
    selectAddressSuggestion (item) {
      this.updateAddress(item, 'address')
    },
    emitAddress () {
      this.$emit('newAddress', {
        type: this.tagType,
        address: this.address
      })
    },
    geoErrMsg (msg) {
      this.errMsg.geoErrMsg = msg.geoErrMsg
    },
    async handleGeoChange () {
      if (this.isValidGeo(this.address.geo)) {
        this.errMsg.geoErrMsg = null
        const [lat, lon] = this.address.geo.split(',')
        try {
          const response = await fetch(`${this.API_URL}/rgeocode/?lon=${lon}&lat=${lat}&apikey=${this.API_KEY}`)
          const data = await response.json()
          if (data?.items?.length > 0) {
            const responseFirstItem = data.items[0]
            this.address.country = responseFirstItem.regionalCountry?.name || ''
            const regionalCountry = responseFirstItem.regionalStructure?.find(i => i.type === 'regional.country')?.name || ''
            const regionalRegion = responseFirstItem.regionalStructure?.find(i => i.type === 'regional.region')?.name || ''
            const regionalDistrict = responseFirstItem.regionalStructure?.find(i => i.type === 'regional.district')?.name || ''

            this.address.country = regionalCountry
            this.address.region = regionalRegion
            this.address.district = regionalDistrict

            this.emitAddress()
          } else {
            this.errMsg.geoErrMsg = 'No results found for this GPS'
          }
        } catch (error) {
          this.errMsg.geoErrMsg = 'Error fetching address from GPS'
        }
      } else {
        this.errMsg.geoErrMsg = 'Invalid GPS coordinates'
      }
    }
  },
  watch: {
    tagTypeInfo: {
      deep: true,
      handler (newVal) {
        if (newVal) {
          const source = newVal
          Object.keys(this.address).forEach(key => {
            if (key in source) {
              this.address[key] = source[key]
            }
          })
        }
      }
    }
  },
  mounted () {
    if (this.tag && this.tagType && this.tag.additionalInfo[this.tagType]) {
      const source = this.tag.additionalInfo[this.tagType]
      Object.keys(this.address).forEach(key => {
        if (key in source) {
          this.address[key] = source[key]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.autocomplete-wrapper {
  position: relative;
}

.autocomplete-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 8px 10px;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }
}
</style>
