<template>
  <ModuleView>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <ButtonDelete v-if="isDeleteButtonVisible" sourceType="tag" @deleteRecord="deleteTag" />
              <ButtonClose route-name="commonTags_list" />
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-12">
                    <header v-if="showEditComponents" class="card card-body m-0 mb-5 p-3 edit-header">
                      <h6 class="m-0">
                        <span>id:</span> {{ tag.id }}
                        <span>title:</span> {{ tag.title }}
                        <span>type:</span> {{ tag.type | capitalize}}
                        <span>created at:</span> {{ tag.createdAt | prettyDateTime}}
                        <span>modified at:</span> {{ tag.modifiedAt | prettyDateTime}}
                      </h6>
                    </header>
                    <main>
                      <div class="row">
                        <!-- *** First col *** -->
                        <div class="col-lg-6 geenea-tag-first-col">
                          <!-- General information -->
                          <div class="geneea-tag-generalInfo">
                            <h3> {{ $t('tag.geneeaTag.generalInfo.title') }} </h3>
                            <Input
                              v-model="tag.title"
                              @blur="$v.tag.title.$touch()"
                              :error="$v.tag.title.$error || formErrors.type === 'duplicate_name'"
                              id="tag_title"
                              :label="$t('tag.title') + ' ' + '*'"
                              :disabled="isDisabled('title')"
                            />
                            <Input
                              v-if="showEditComponents"
                              v-model="tag.id"
                              :label="$t('tag.id')"
                              :disabled="isDisabled('id')"
                            />
                            <SelectInput
                              v-model="tag.type"
                              id="rubric_product"
                              optionTitle="title"
                              :options="TagTypesArray"
                              :noEmptyValue="true"
                              :required="false"
                              :label="$t('tag.type')"
                              :disabled="isDisabled('type')"
                            />
                            <Input
                              v-model="tag.slug"
                              @blur="$v.tag.slug.$touch()"
                              :error="$v.tag.slug.$error"
                              id="tag_slug"
                              :label="$t('tag.geneeaTag.generalInfo.slug') + ' ' + '*'"
                            />
                            <div class="title-text">
                              {{ $t('tag.description') }}
                            </div>
                            <RichTextEditor
                              v-model="tag.description"
                              :other-options="richTextEditorConfig"
                              id="geneeaTag_description"
                              class="mb-3"
                            />
                            <div class="title-text">
                              {{ $t('tag.image') }}
                            </div>
                            <ModuleFormPhotoBox
                              :image="tag.image"
                              :media-usage-type="tagMediaType"
                              hide-separator
                              @set-media="setTagImage"
                              @remove-media="removeTagImage"
                              class="mb-3"
                            />
                            <div class="form-group">
                              <label>{{ $t('article.related_articles') }}</label><br>
                              <button
                                class="btn btn-info"
                                data-test="article_btn_select_related_articles"
                                @click="switchRelatedArticleModal(true)"
                              >
                                {{ $t('buttons.select_articles') }}
                              </button>
                              <RelatedArticlesModal
                                v-if="relatedArticleModal"
                                @close="switchRelatedArticleModal(false)"
                                @set-data="addRelatedArticles"
                                multiple
                              />
                              <div class="m-t-10">
                                <RelatedArticleList
                                  :relatedArticlesProp="relatedArticles"
                                  @removeRelatedArticle="removeRelatedArticle"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- *** Second col *** -->
                        <div class="col-lg-6 geenea-tag-second-col">
                          <!--  event  -->
                          <div v-if="tag.type === TagTypes.EVENT" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.event.title') }} </h3>
                            <div class="row">
                              <Datepicker
                                type="date"
                                v-model="tag.additionalInfo.event.startDate"
                                id="tag-additionalInfo-event-startDate"
                                :label="$t('tag.geneeaTag.event.startDate')"
                                :placeholder="$t('tag.geneeaTag.event.startDate')"
                                :max-date="convertToDate(tag.additionalInfo.event.endDate) || null"
                                class="col-lg-6"
                              />
                              <Datepicker
                                type="date"
                                v-model="tag.additionalInfo.event.endDate"
                                id="tag-additionalInfo-event-endDate"
                                :label="$t('tag.geneeaTag.event.endDate')"
                                :placeholder="$t('tag.geneeaTag.event.endDate')"
                                :min-date="convertToDate(tag.additionalInfo.event.startDate) || null"
                                class="col-lg-6"
                              />
                            </div>
                            <AddressInput @newAddress="setNewAddress" tagType="event" :tag="tag"/>
                          </div>
                          <!--  location  -->
                          <div v-if="tag.type === TagTypes.LOCATION" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.location.title') }} </h3>
                            <AddressInput @newAddress="setNewAddress" :tagType="TagTypes.LOCATION" :tag="tag"/>
                            <Input
                              v-model.number="tag.additionalInfo.location.population"
                              class="mt-2"
                              :label="$t('tag.geneeaTag.location.population')"
                              type="number"
                              :min="1"
                              :max="2147483000"
                            />
                          </div>
                          <!--  organisation  -->
                          <div v-if="tag.type === TagTypes.ORGANISATION" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.organisation.title') }} </h3>
                            <Input
                              v-model="tag.additionalInfo.organisation.alternateName"
                              :label="$t('tag.geneeaTag.organisation.alternateName')"
                            />
                            <AddressInput @newAddress="setNewAddress" tagType="organisation" :tag="tag"/>
                            <div class="title-text">
                              {{ $t('tag.geneeaTag.organisation.logo') }}
                            </div>
                            <ModuleFormPhotoBox
                              :image="tag.additionalInfo.organisation.logoImage"
                              :media-usage-type="tagMediaType"
                              hide-separator
                              @set-media="setLogoImg"
                              @remove-media="removeLogoImage"
                              class="mb-3"
                            />
                          </div>
                          <!--  person  -->
                          <div v-if="tag.type === TagTypes.PERSON" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.person.title') }} </h3>
                            <Input
                              v-model="tag.additionalInfo.person.givenName"
                              :label="$t('tag.geneeaTag.person.givenName')"
                            />
                            <Input
                              v-model="tag.additionalInfo.person.familyName"
                              :label="$t('tag.geneeaTag.person.familyName')"
                            />
                            <Input
                              v-model="tag.additionalInfo.person.hasOccupation"
                              :label="$t('tag.geneeaTag.person.hasOccupation')"
                            />
                            <div class="row">
                              <Datepicker
                                type="date"
                                v-model="tag.additionalInfo.person.birthDate"
                                id="tag-additionalInfo-person-birthDate"
                                :label="$t('tag.geneeaTag.person.birthDate')"
                                :placeholder="$t('tag.geneeaTag.person.birthDate')"
                                :max-date="convertToDate(tag.additionalInfo.person.deathDate) || null"
                                class="col-lg-4"
                              />
                              <Datepicker
                                type="date"
                                v-model="tag.additionalInfo.person.deathDate"
                                id="tag-additionalInfo-person-deathDate"
                                :label="$t('tag.geneeaTag.person.deathDate')"
                                :placeholder="$t('tag.geneeaTag.person.deathDate')"
                                :min-date="convertToDate(tag.additionalInfo.person.birthDate) || null"
                                class="col-lg-4"
                              />
                            </div>
                            <Input
                              v-model="tag.additionalInfo.person.birthPlace"
                              :label="$t('tag.geneeaTag.person.birthPlace')"
                            />
                            <Input
                              v-model="tag.additionalInfo.person.deathPlace"
                              :label="$t('tag.geneeaTag.person.deathPlace')"
                            />
                          </div>
                          <!--  product  -->
                          <div v-if="tag.type === TagTypes.PRODUCT" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.product.title') }} </h3>
                            <Input
                              v-model="tag.additionalInfo.product.manufacturer"
                              :label="$t('tag.geneeaTag.product.manufacturer')"
                            />
                            <Input
                              v-model="tag.additionalInfo.product.model"
                              :label="$t('tag.geneeaTag.product.model')"
                            />
                          </div>
                          <!--  general  -->
                          <div v-if="tag.type === TagTypes.GENERAL" class="geneea-tag-additionalInfo">
                            <h3> {{ $t('tag.geneeaTag.general.title') }} </h3>
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </moduleView>
</template>

<script>

import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import Input from '@/components/form/inputs/Input.vue'
import AddressInput from '@/components/address/AddressInput.vue'
import Datepicker from '@/components/form/Datepicker'
import ModuleView from '@/components/ModuleView.vue'
import RelatedArticleList from '@/components/article/ArticleRelatedList.vue'
import RelatedArticlesModal from '@/components/article/ArticleRelatedModal.vue'
import ButtonDelete from '@/components/shared/ButtonDelete.vue'
import ButtonClose from '@/components/shared/ButtonClose.vue'
import NotifyService from '@/services/NotifyService'
import PermissionService from '@/services/PermissionService'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox.vue'
import EditorialTagModel from '@/model/EditorialTagModel'
import { TagTypes } from '@/model/ValueObject/TagTypes'
import { MEDIA_USAGE_TYPE_TAG } from '@/model/ValueObject/MediaUsageTypes'
import { capitalize } from '@/filters'
import RichTextEditor from '@/components/form/RichTextEditor.vue'
import TinyMceConfig from '@/services/tinymce/TinyMceConfig'
import SelectInput from '@/components/form/select/Select.vue'
import TagFilter from '@/model/TagFilter'

export default {
  name: 'TagNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      filter: this._.cloneDeep(TagFilter),
      tag: this._.cloneDeep(EditorialTagModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS,
      showEditComponents: false,
      richTextEditorConfig: TinyMceConfig.getConfig(),
      relatedArticleModal: false,
      relatedArticles: [],
      relatedArticlesIds: [],
      tagMediaType: MEDIA_USAGE_TYPE_TAG,
      disabledInputs: ['id']
    }
  },
  validations: {
    tag: {
      title: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(60)
      },
      slug: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(60)
      }
    }
  },
  components: {
    SelectInput,
    AddressInput,
    RichTextEditor,
    RelatedArticleList,
    RelatedArticlesModal,
    ModuleFormPhotoBox,
    Input,
    Datepicker,
    ButtonClose,
    ButtonDelete,
    ModuleView
  },
  computed: {
    ...mapGetters('tag', [
      'formErrors'
    ]),
    TagTypes () {
      return TagTypes
    },
    TagTypesArray () {
      const defaultVal = 'general'
      const allTypes = Object.values(this.TagTypes).map(type => {
        return { title: type, id: type }
      })
      const generalType = allTypes.find(type => type.id === defaultVal)
      const otherTypes = allTypes.filter(type => type.id !== defaultVal)
      return generalType ? [generalType, ...otherTypes] : otherTypes
    },
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isDeleteButtonVisible () {
      return this.tag?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    }
  },
  methods: {
    capitalize,
    convertToDate (dateString) {
      return dateString ? new Date(dateString) : null
    },
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        try {
          const hasTag = await this.setFilterAndCheckTag()

          if (hasTag.length !== 0) {
            const tagAlreadyExistsMsg = this.$t('tag.tag_name_already_exists', { name: this.tag.title })
            this.$store.commit('tag/SET_FORM_ERRORS', { name: tagAlreadyExistsMsg, type: 'duplicate_name' })
            NotifyService.setErrorMessage(tagAlreadyExistsMsg)
          } else {
            await this.$store.dispatch('tag/create', this.tag)
            if (this.$store.getters['tag/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.$router.push('/commonTags')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['tag/error'])
            }
          }
        } catch (error) {
          console.log(error)
        } finally {
          this.resetFilter()
        }
      }
    },
    deleteTag () {
      this.$store.dispatch('tag/deleteRecord', this.tag)
        .then(() => {
          if (this.$store.getters['tag/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/tag')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['tag/error'])
          }
        })
        .catch(error => console.log(error))
    },
    async setFilterAndCheckTag () {
      this.$store.commit('tag/setPage', 1)
      this.filter.titleExact = this.tag.title
      this.$store.commit('tag/setFilter', this.filter)

      try {
        const response = await this.$store.dispatch('tag/fetch')
        return response.data
      } catch (error) {
        console.error('Error fetching tag:', error)
        throw error
      }
    },
    resetFilter () {
      this.$store.commit('tag/setPage', 1)
      this.filter = this._.cloneDeep(TagFilter)
      this.$store.commit('tag/setFilter', this.filter)
    },
    isDisabled (input) {
      return this.disabledInputs.includes(input)
    },
    setNewAddress (data) {
      this.tag.additionalInfo[data.type] = { ...this.tag.additionalInfo[data.type], ...data.address }
    },
    addRelatedArticles (newRelatedArticles) {
      const existingIds = new Set(this.relatedArticles.map(article => article.id))
      const uniqueNewArticles = newRelatedArticles.filter(article => !existingIds.has(article.id))
      this.relatedArticles = [...this.relatedArticles, ...uniqueNewArticles]
      this.relatedArticlesIds = this.relatedArticles.map(article => article.id)
    },
    removeRelatedArticle (article) {
      if (!article || !article.id) {
        console.error('Article is not valid')
        return
      }
      this.relatedArticles = this.relatedArticles.filter(item => item.id !== article.id)
      this.relatedArticlesIds = this.relatedArticlesIds.filter(item => item !== article.id)
    },
    switchRelatedArticleModal (showModal) {
      this.relatedArticleModal = showModal
    },
    setTagImage (image) {
      this.tag.image = image[0]
      this.tag.imageId = image[0].id
    },
    removeTagImage () {
      this.tag.image = {}
      this.tag.imageId = null
    },
    setLogoImg (image) {
      this.tag.additionalInfo.organisation.logoImage = image[0]
      this.tag.additionalInfo.organisation.logoImageId = image[0].id
    },
    removeLogoImage () {
      this.tag.additionalInfo.organisation.logoImage = {}
      this.tag.additionalInfo.organisation.logoImageId = null
    }
  },
  mounted () {
    this.$store.commit('tag/SET_FORM_ERRORS', {})
    if (!this.tag.type) {
      this.tag.type = TagTypes.GENERAL
    }
  },
  watch: {
    relatedArticles: {
      deep: true,
      handler (newVal) {
        this.tag.relatedArticles = newVal
      }
    },
    relatedArticlesIds: {
      deep: true,
      handler (newVal) {
        this.tag.relatedArticlesIds = newVal
      }
    }
  }
}
</script>

<style lang="scss">

.edit-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  span {
    color: #bbb;
    font-weight: 100;
    margin-left: 3rem;
  }
  span:nth-child(1) {
    margin-left: 1rem;
  }
}

</style>
